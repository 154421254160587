:root {
  line-height: 1.5;
  font-weight: 400;

  color: rgba(255, 255, 255, 0.87);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

@font-face {
  font-family: "text-font";
  src: url("/public/tipografia/LibreFranklin-Regular.ttf");
}
@font-face {
  font-family: "title-font";
  src: url("/public/tipografia/RobotoSlab-Bold.ttf");
}

body {
  margin: 0;
  display: flex;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

h2{
  font-size: 2.8em;
  line-height: 1.1;
}

h3{
  font-size: 2.4em;
  line-height: 1.1;
}

h1, h2, h3{
  font-family: "title-font";
  color: #0A4789;
    margin: 0.5em;
}

p, a{
  font-family: "text-font";
  font-size: 1.3em;
  line-height: 1.8;
  color: #1a1a1a;
  margin: 0.5em;
}

span{
  color: #000000;
  font-family: "text-font";
}


@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1.7em;
  }

  h3{
    font-size: 1.5em;
  }

  p{
    font-family: "text-font";
    font-size: 1.1em;
    line-height: 1.5;
    color: #1a1a1a;
    margin: 0.5em;
  }

  button{
    font-size: 0.9em;
    padding: 0.6em 1em;
  }
}

