#root {
  width: 100%;
}

.gradient{
  background: linear-gradient(0deg, rgba(171,12,12,1) 0%, rgba(255,255,255,1) 100%);
}

.logo{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

video{
  width: 100%;
  pointer-events: none; 
}

.video{
  width: 60%;
  position: relative;
  display:flex;
  justify-content:center; 
  align-items:center;
  flex-direction:column;
}

.btn-reproducir{
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 4em 0;
  background:#f5f5f5;
  justify-content: center;
}


.bgSpan{
  border-radius:20px; 
  background: #FDFDFD ;
  padding:1.2rem 3rem;
}

.btn-PlayPause{
  height: 15px;
  background-color:#dfdfdf;
}

progress[value] {
  appearance: none;
  border: none;
  width: 100%;
}

progress[value]::-webkit-progress-value {
  background-color:  #083564;
}

.container-info{
  padding: 2rem;
  max-width: 1280px;
  margin: 0 auto;
}

.wrapper-req{
  background:rgb(185,8,8,33%);
  background-image:url('/public/canada.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius:20px;
  padding: 2rem 3rem;
}

.container-req > *{
 margin: auto;
 display: flex;
}

.container{
  padding: 2rem 0;
  max-width: 1280px;
  margin: 0 auto;
}

.footer-container{
  display: grid;
  justify-content: space-around;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.container-decoration{
  display: flex;
  justify-content: center;
  margin: 2em 0;
}

.decoration{
  border-bottom: solid #0A4789 !important;
  box-shadow: 0px 3px 3px #00000067;
  width: 95%;
}

.contacto{
  display: grid;
}

.contacto p{
  margin: auto 0 ;
  font-size: 1em;
  color: #0A4789;
}

.btnsRedes{
  display: grid;
  grid-template-columns: 15% 15%;
  margin: 15px 0 0 0 ;
}

.btnIg, .btnFb{
  background: none;
  border: none;
  fill: #0A4789;
  margin:0;
}

.derechos{
  grid-column-start: 2;
  text-align: center;
}

.derechos p{
  margin-bottom: 3%;
  font-size: 1em;
  color: #0A4789;
}

.derechos a{
  font-size: 1em;
  color: #3885d6;
  text-decoration: none;
}

.btnUp { 
  justify-content: center;
  display: flex;
}

.btnUp p{
  margin: auto 0 ;
  font-size:1em;
  color: #0A4789;
}

.btnScrollFooter:hover{
  fill: #1874d6;
  color: #1874d6;
}

.btnScrollFooter{
   background: none;
   border: none;
   fill: #0A4789;
   color: #0A4789;
}

.btn-contact {
  border-radius: 20px;
  color: #F5F5F5;
  border: 1px solid transparent;
  padding: 1em 1.3em;
  font-size: 1em;
  font-family: "title-font";
  background-color: #0A4789;
  cursor: pointer;
  transition: border-color 0.25s;
}
.btn-contact:hover {
  border-color: #f5f5f5;
}

.btn-home{
  cursor: pointer;
  font-size: 1em;
  font-family: "title-font";
  background: none;
  color:#0A4789; 
  border: 1px solid #0A4789;
  padding: 0.5em;
  border-radius:15px;
}

.btn-home:hover {
  background-color: #0A4789;
  color: #F5F5F5;
}

@media only screen and (max-width: 500px){
  .btn-reproducir{
   height: 2rem;
   justify-content: center;
   width: 18rem;
   font-size: 12px;
  }
}

@media only screen and (max-width: 925px) {

  .container-info{
    padding: 2rem 1rem;
    max-width: 1280px;
    margin: 0 auto;
  }

  .footer-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
  }
  .contacto{
      order: 1;
      text-align: center;
      margin: 4%;
  }
  
  .contacto p{
    line-height: 1.8;
  }

  .btnsRedes{
    justify-content: center;
  }

  .btnUp{
      order: 2;
      align-self: center;
  }
  .derechos{
      order: 4;
      text-align: center;
  }
}
